import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import App from './App.vue'
import store from './store'
import router from './router'

// Global Components
import './global-components'

// 3rd party plugins
// import '@axios'
// import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/toastification'

// import PouchDB from 'pouchdb-browser'
import PouchDB from 'pouchdb'
import Find from 'pouchdb-find'
import LiveFind from 'pouchdb-live-find'
import PouchdbAuthentication from 'pouchdb-authentication'
// import AddCorsToCouchDB from 'add-cors-to-couchdb'
// import PouchVue from 'vue-pouch'

Vue.config.productionTip = true

PouchDB.plugin(Find)
PouchDB.plugin(LiveFind)
PouchDB.plugin(PouchdbAuthentication)
// PouchDB.plugin(AddCorsToCouchDB)

// Vue.use(PouchVue, {
//   pouch: PouchDB,
//   defaultDB: '',
//   // defaultUsername: 'admin',
//   // defaultPassword: 'Burotica49',
// })

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
  created() {    
    // Prevent blank screen in Electron builds
    this.$router.push('/')
  }
}).$mount('#app')

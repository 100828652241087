import Vue from 'vue'
import VueRouter from 'vue-router'
import PouchDB from 'pouchdb'
import PouchdbAuthentication from 'pouchdb-authentication'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        authRequired: true,
        pageTitle: 'Accueil',
        breadcrumb: [
          {
            text: 'Accueil',
            active: true,
          },
        ],
      },
    },
    {
      path: '/supervision/serenityrapport',
      name: 'serenityrapport',
      component: () => import('@/views/components/supervision/SerenityRapport.vue'),
      meta: {
        authRequired: true,
        pageTitle: 'Rapport Serenity',
        breadcrumb: [
          {
            text: 'Rapport Serenity',
            active: true,
          },
        ],
      },
    },
    {
      path: '/supervision/backupgestionrapport',
      name: 'backupgestionrapport',
      component: () => import('@/views/components/supervision/RapportBackupGestion.vue'),
      meta: {
        authRequired: true,
        pageTitle: 'Rapport Backup Gestion',
        breadcrumb: [
          {
            text: 'Rapport Backup Gestion',
            active: true,
          },
        ],
      },
    },
    {
      path: '/supervision/supervision/viewbackupgestion/:id',
      name: 'viewbackupgestion',
      component: () => import('@/views/components/supervision/BackupGestionView'),
      meta: {
        authRequired: true,
        pageTitle: 'Rapport de Backup gestion',
        breadcrumb: [
          {
            text: 'Rapport de Backup  gestion',
            active: true,
          },
        ],
      },
    },
    {
      path: '/second-page',
      name: 'second-page',
      component: () => import('@/views/SecondPage.vue'),
      meta: {
        authRequired: true,
        pageTitle: 'Second Page',
        breadcrumb: [
          {
            text: 'Second Page',
            active: true,
          },
        ],
      },
    },
    {
      path: '/clients/listclient',
      name: 'listclient',
      component: () => import('@/views/components/clients/ClientList'),
      meta: {
        authRequired: true,
        pageTitle: 'Liste des clients',
        breadcrumb: [
          {
            text: 'Liste des clients',
            active: true,
          },
        ],
      },
    },
    {
      path: '/clients/viewclient/:id',
      name: 'viewclient',
      component: () => import('@/views/components/clients/ClientView'),
      meta: {
        authRequired: true,
        pageTitle: 'Coordonnées client',
        breadcrumb: [
          {
            text: 'Coordonnées client',
            active: true,
          },
        ],
      },
    },
    {
      path: '/clients/viewclientref/:id',
      name: 'viewclientref',
      component: () => import('@/views/components/clients/ClientViewRef'),
      meta: {
        authRequired: true,
        pageTitle: 'Coordonnées client',
        breadcrumb: [
          {
            text: 'Coordonnées client',
            active: true,
          },
        ],
      },
    },
    {
      path: '/frais/listfrais',
      name: 'listfrais',
      component: () => import('@/views/components/frais/FraisList'),
      meta: {
        authRequired: true,
        pageTitle: 'Liste notes de frais',
        breadcrumb: [
          {
            text: 'Liste notes de frais',
            active: true,
          },
        ],
      },
    },
    {
      path: '/frais/createfrais',
      name: 'createfrais',
      component: () => import('@/views/components/frais/FraisAdd'),
      meta: {
        authRequired: true,
        pageTitle: 'Ajouter note de frais',
        breadcrumb: [
          {
            text: 'Ajouter note de frais',
            active: true,
          },
        ],
      },
    },
    {
      path: '/frais/printfrais/:id',
      name: 'printfrais',
      component: () => import('@/views/components/frais/FraisPrint'),
      meta: {
        authRequired: true,
        pageTitle: 'Note de frais',
        breadcrumb: [
          {
            text: 'Note de frais',
            active: true,
          },
        ],
      },
    },
    {
      path: '/camera',
      name: 'camera',
      component: () => import('@/views/components/Camera'),
      meta: {
        authRequired: true,
        pageTitle: 'Camera',
        breadcrumb: [
          {
            text: 'Camera',
            active: true,
          },
        ],
      },
    },
    {
      path: '/barcodereader',
      name: 'barcodereader',
      component: () => import('@/views/components/BarcodeReader'),
      meta: {
        authRequired: true,
        pageTitle: 'Barcode Reader',
        breadcrumb: [
          {
            text: 'Barcode Reader',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})


router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  // const publicPages = ['/login']
  // const authRequired = !publicPages.includes(to.path)
  const sessionDB = new PouchDB('https://couchdb.placedusite.fr/_session')
  sessionDB.getSession().then(response => {
    console.log("getSession", response)
    if (!response.userCtx.name) {
      console.log("nobody's logged in")
      localStorage.removeItem('user')
      localStorage.removeItem('role')
    } else {
      // localStorage.setItem('user', response.userCtx.name)
      // localStorage.setItem('role', response.userCtx.roles)
      console.log("local user", localStorage.getItem('user'))
      console.log("local role", localStorage.getItem('role'))
    } 
  }).catch ((error) =>  {
    console.error("getSessionError", error)
    next({ path: '/login', })
    return
  })

  // const loggedIn = localStorage.getItem('user')

  if (to.matched.some(record => record.meta.authRequired)) {
    if (localStorage.getItem('user')) {
      next();
    }
    else {
      next({ path: '/login', })
      return
    }
  }
  else {
    next();
  }
})


// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
